// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from 'jquery'
global.$ = jQuery

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

window.Highcharts = Highcharts;
import Highcharts from 'highcharts';
import addMore from 'highcharts/highcharts-more';
require('highcharts/modules/no-data-to-display')(Highcharts)
addMore(Highcharts)

import 'bootstrap'
import 'underscore'
import "trix/dist/trix.css"

require("packs/google_analytics")
require("packs/picker.date")
require("packs/picker")
require("packs/picker.time")
require("packs/awesomplete")
require("packs/date_time_picker")
require("packs/popper.min")